import { Injectable } from '@angular/core';
import { Contact } from './contact.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  // readonly baseURL = 'https://localhost:44379/api/Contact/Sendmail'
  readonly baseURL = 'http://support.latestdevelopers.com/api/Contact/Sendmail'
  formData : Contact = new Contact();
  constructor(private http: HttpClient) { }
  
  Sendmail(){
    console.log(this.formData);
    return this.http.post(this.baseURL, this.formData);
  }
}
