import { Component, OnInit } from '@angular/core';
import { ContactService } from './contact.service';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Contact } from './contact.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {
  formData: Contact = new Contact();  
  constructor(private service : ContactService, private route: ActivatedRoute) { }
  
  ngOnInit() { }

  onSubmit(form: NgForm){
    if(confirm('Are you sure? ')){
      this.service.formData = this.formData;
      this.service.Sendmail().subscribe(err => {console.log(err);});
    }
  }

}
