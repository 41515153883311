import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { DesignComponent } from './Latest/design/design.component';
import { DevelopmentComponent } from './Latest/development/development.component';
import { OthersComponent } from './Latest/others/others.component';
import { PricingComponent } from './Latest/pricing/pricing.component';
import { HireComponent } from './Latest/hire/hire.component';
import { ContactComponent } from './Latest/contact/contact.component';
import { HttpClientModule } from '@angular/common/http';

const routes: Routes =[
    { path: 'home', component: HomeComponent },
    { path: 'user-profile', component: ProfileComponent },
    { path: 'register', component: SignupComponent },
    { path: 'landing', component: LandingComponent },
    { path: 'login', component: LoginComponent },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'design', component: DesignComponent},
    { path: 'development', component: DevelopmentComponent},
    { path: 'others', component: OthersComponent},
    { path: 'pricing', component:PricingComponent},
    { path: 'hiring', component:HireComponent},
    { path: 'contact', component:ContactComponent},
    
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
